import React, { useState } from 'react'
import Images from './../components/Images';
import MenuIcon from '@mui/icons-material/Menu';
import "../styles/superAdmin.css"
import Cluster from '../components/Cluster';
import Routes from '../components/Routes';
import BusTable from '../components/BusTable';
import Student from '../components/Student';
import { useSelector } from 'react-redux';
import Image from "../images/BannerImage.png"
import useAdmin from '../customhooks/useAdmin';
import FinanceFilter from '../components/FinanceFilter';

function SuperAdmin({ admin }) {
    const [collapsed, setCollapsed] = useState(false)
    const [component, setComponent] = useState("cluster")
    const buses = useSelector((state) => state.busState.buses)
    const students = useSelector((state) => state.studentState.students)
    const { logout } = useAdmin();
    function handleClick(){
      setCollapsed(!collapsed)
    }

    function renderComponent(){
        switch(component){
            case "cluster": return <Cluster admin={admin}/>
            case "buses": return <BusTable admin={admin}/>
            case "routes": return <Routes admin={admin}/>
            case "student": return <Student admin={admin}/>
            case "finance": return <FinanceFilter />
            default: return <Cluster admin={admin}/>
        }
    }

    function handleLogOut(){
        logout()
      }

  return (
    <div className='superAdminContainer'>
      <div className="Banner">
      <Images imageLink={Image} imageText={"CMRGI Banner"} styles={{height:"120px"}}/>
        </div>
        <div className="superAdminScreen">
            <div className="left">
            <div className={`navbar ${collapsed ? 'collapsed' : ''}`}>
                <MenuIcon onClick={handleClick} style={{cursor: "pointer"}}/>
                <div className="menu" style={{fontWeight:"bold"}}>
                    <div onClick={() => setComponent("cluster")}>{!collapsed && (admin ? "View Cluster" :'Manage Cluster')}</div>
                    <div onClick={() => setComponent("buses")}>{!collapsed && (admin ? "View Bus" : 'Manage Bus')}</div>
                    <div onClick={() => setComponent("routes")}>{!collapsed && (admin ? "View Route" :'Manage Route')}</div>
                    <div onClick={() => setComponent("student")}>{!collapsed && (admin ? "View Students" :'Manage Students')}</div>
                    {!admin ? <div onClick={() => setComponent("finance")}>{!collapsed && "View Finance"}</div> : <div style={{display: "none"}}></div> }
                </div>
                </div>
            </div>
            <div className="right">
                <div style={{textAlign: "right", padding: "0px 20px"}}>
                    <button style={{width: "fit-content", padding: "10px", margin: "10px"}} onClick={handleLogOut}>LogOut</button>
                </div>
                <div style={{width: "100%", display: 'flex', alignItems: "center", justifyContent: "center", textAlign: "center", padding: "1em", backgroundColor: "#F6821F", borderRadius: "10px", color: "white"}}>
                    <div className="bannerLeft">
                        <h2>Welcome to the {admin ? "" : "Super"} Admin Portal of CMRGI Transport Management</h2>
                    </div>
                    <div className="bannerRight">
                        <h2 className="inputDataFieldsSingle" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>Total Buses: <span style={{color: "black", width: "fit-content", padding: "5px"}}>{buses.length !== 0 ? buses.length : 0}</span></h2>
                        <h2 className="inputDataFieldsSingle" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>Seats Filled: <span style={{color: "black", width: "fit-content", padding: "5px"}}>{buses.length !== 0 ? students.length : 0}</span></h2>
                        <h2 className="inputDataFieldsSingle" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>Seats Vacant: <span style={{color: "black", width: "fit-content", padding: "5px"}}>{buses.length !== 0 ? buses.length * 56 - students.length : 0}</span></h2>
                    </div>
                </div>
                {renderComponent()}
            </div>
        </div>
    </div>
  )
}

export default SuperAdmin
