import React, { useState } from 'react'
import Images from '../components/Images';
import NavComponent from '../components/NavComponent';
import MenuIcon from '@mui/icons-material/Menu';
import Image from "../images/BannerImage.png"

function Header() {
  const [mobileView, setMobileView] = useState(false)
  function handleClick(){
    setMobileView(!mobileView)
  }
  return (
    <div>
      <div className="Banner">
            <Images imageLink={Image} imageText={"CMRGI Banner"} styles={{height:"120px"}}/>
        </div>
        <div className="NavBar">
          <div className="mobileNavBar">
            <div className="menu">
            <MenuIcon onClick={handleClick}/>
            </div>
            {mobileView ? 
              <div className='mobileActualNavBar'>
                <NavComponent routeLink={"/"} NavName={"Home"}/>
                <NavComponent routeLink={"/about"} NavName={"About"}/>
                <NavComponent routeLink={"/busRoutes"} NavName={"Bus Routes"}/>
              </div>
            : <div> </div>}
          </div>{
            <div className='ActualNavBar'>
                <NavComponent routeLink={"/"} NavName={"Home"}/>
                <NavComponent routeLink={"/about"} NavName={"About"}/>
                <NavComponent routeLink={"/busRoutes"} NavName={"Bus Routes"}/>
            </div>
          }
        </div>
    </div>
  )
}

export default Header
