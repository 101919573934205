import { createSlice, configureStore } from "@reduxjs/toolkit"

const busInitialState = {buses: [], error: null, loading: false, message: "", tempBuses: []}
const stopInitialState = {stops: [], error: null, loading: false, message: "", tempStops: []}
const clusterInitialState = {clusters: [], error: null, loading: false, message: ""}
const studentInitialState = {students: [], error: null, loading: false, message: "", tempStudents: [], student: []}
const adminInitialState = {isAuthenticated: false, loading: false}
const pendingStudentsInitialState = {pendingStudents: [], error: null, loading: false}

const busSlice = createSlice({
    name: "buses",
    initialState: busInitialState,
    reducers:{
        setBuses(state, action) {
            state.buses = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setMessage(state, action) {
            state.message = action.payload
        },
        setTempBuses(state, action) {
            state.tempBuses = action.payload
        }
    }
})

const stopSlice = createSlice({
    name: "stops",
    initialState: stopInitialState,
    reducers:{
        setStops(state, action) {
            state.stops = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setMessage(state, action) {
            state.message = action.payload
        },
        setTempStops(state, action) {
            state.tempStops = action.payload
        }
    }
})

const clusterSlice = createSlice({
    name: "clusters",
    initialState: clusterInitialState,
    reducers:{
        setClusters(state, action) {
            state.clusters = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setMessage(state, action) {
            state.message = action.payload
        },
        setTempStops(state, action) {
            state.tempStops = action.payload
        }
    }
})

const studentSlice = createSlice({
    name: "students",
    initialState: studentInitialState,
    reducers: {
        setStudents(state, action){
            state.students = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setMessage(state, action) {
            state.message = action.payload
        },
        setTempStudents(state, action){
            state.tempStudents = action.payload
        },
        setStudent(state, action){
            state.student = action.payload
        }
    }
})

const adminSlice = createSlice({
    name: "admin",
    initialState: adminInitialState,
    reducers: {
        setUserName(state, action){
            state.userName = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setMessage(state, action) {
            state.message = action.payload
        },
        setIsAuthenticated(state, action){
            state.isAuthenticated = action.payload
        }
    }
})

const pendingStudentSlice = createSlice({
    name: "pendingStudent",
    initialState: pendingStudentsInitialState,
    reducers: {
        setPendingStudents(state, action){
            state.pendingStudents = action.payload
        },
        setLoading(state, action) {
            state.loading = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
    }
})

const store = configureStore({
    reducer: {
        busState: busSlice.reducer,
        stopState: stopSlice.reducer,
        clusterState: clusterSlice.reducer,
        studentState: studentSlice.reducer,
        adminState: adminSlice.reducer,
        pendingStudentState: pendingStudentSlice.reducer,
    }
})


export const busActions = busSlice.actions
export const stopActions = stopSlice.actions
export const clusterActions = clusterSlice.actions
export const studentActions = studentSlice.actions
export const adminActions = adminSlice.actions
export const pendingStudentActions = pendingStudentSlice.actions
export default store;