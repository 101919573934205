import { useNavigate } from "react-router-dom"

function BusNo({ cluster, busNo }){
    const navigate = useNavigate()
    function handleBusNoClick(){
      navigate("/busRoutes/bus/" + cluster + "/" + busNo)
    }
    return (
      <p className='hoverable' onClick={handleBusNoClick}>{busNo}</p>
    )
}

export default BusNo
  