import React, { useEffect } from 'react'
import Header from '../components/Header'
import { useLocation, useNavigate } from "react-router-dom"
import data from '../Data/data'
import BusNo from '../components/BusNo'
import useStudent from '../customhooks/useStudent'
import useStops from '../customhooks/useStops'
import useCluster from '../customhooks/useCluster'
import useBus from '../customhooks/useBus'
import { useSelector } from 'react-redux'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function BusInfo() {
  const navigate = useNavigate();
  const location = useLocation()
  const id = location.pathname.split("/").slice(-1)[0]
  const { fetchBuses, converIdToBus } = useBus()
  const { fetchClusters } = useCluster()
  const { fetchStops } = useStops()
  const {fetchStudents} = useStudent()
  
  useEffect(() => {
    fetchStops()
    fetchBuses()
    fetchClusters()
    fetchStudents()
  }, [])
  
  const clusters = useSelector(state => state.clusterState.clusters)
  const arr = clusters.filter((cluster) => cluster.cluster === id)
  let buses = []
  // console.log(arr, clusters, id)
  if(arr.length !== 0){
    for(let i = 0; i < arr[0].busRoutes.length; i++){
      const busData = converIdToBus(arr[0].busRoutes[i])
      if (busData.length === 0) continue
      buses.push(busData)
    }
  }

  function handleBackClick(){
    navigate(-1)
  }

  // console.log(buses)
  return (
    <>
    <Header />
    <div className="backbutton" style={{backgroundColor: "#F6821F", width: "fit-content", borderRadius: "50%", padding: "5px", margin: "5px", cursor: "pointer"}} onClick={handleBackClick}>
        <ArrowBackIcon />
      </div>
    <div className='Busroutes'>
      <h1 className='header'>{arr.clusterName}</h1>
      <div className="Wrapper">
        <table className='table'>
          <thead>
            <tr className='row'>
              <th>Route NO</th>  
              <th>Place</th>
            </tr>
          </thead>
          <tbody>
            {buses.length !== 0 ? buses.map((busData) => <BusInfoRow key={busData[0]._id} busNo={busData[0].busNo} place={busData[0].busName} id={id}/>) : <td></td>}
          </tbody>
        </table>
      </div>
      </div>
    </>
  )
}

function BusInfoRow({ busNo, place, id }){
  return (
    <tr className='row'>
      <td className='hoverable'><BusNo busNo={busNo} cluster={id}/></td>
      <td>{place}</td>
    </tr>
  )
}

export default BusInfo
