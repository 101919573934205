import React, { useEffect, useState } from 'react'
import useBus from './../customhooks/useBus';
import "../styles/bustable.css"
import Modal from './Modal';
import BusRegForm from './BusRegForm';
import { useSelector } from "react-redux"
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useStops from './../customhooks/useStops';


function BusTable({ admin }) {
  const [isOpen, setOpen] = useState(false)
  const { fetchBuses } = useBus()
  const { fetchStops } = useStops()
  const buses = useSelector((state) => state.busState.buses)
  const error = useSelector((state) => state.busState.error)
    const BusHeadings = ["busNo", "busName", "driver", "driverNo"]
    useEffect(() => {
      fetchBuses()
      fetchStops()
    }, [])
  return (
    <>
    <div className="busOperations">
        {admin ? <div> </div> : <> <div className='buttons' onClick={() => setOpen(true)} style={{color: "green", padding: "5px 30px", borderRadius: "5px"}}>Add</div>
        <Modal open={isOpen} onClose={() => setOpen(false)}>
            <BusRegForm submit={() => setOpen(false)} data={{busNo : "", busName: "", driver: "", driverNo: "", routeAndTimings: [], _id: ""}}/>
        </Modal></>}
    </div>
    <div className='busTable'>
      <table className=''>
        <thead>
            <tr className='row'>
                {BusHeadings.map((heading, index) => <th key={index} style={{fontSize: "20px"}}>{(heading === "driver" || heading === "driverNo") ? heading === "driver" ? "Bus-Incharge" : "Bus-Incharge Number"  : heading[0].toUpperCase() + heading.slice(1, heading.length)}</th>)}
                {admin ? <> </> : <th style={{fontSize: "20px"}}>Actions</th>}
            </tr>
        </thead>
        <tbody>
            {buses && buses.map((bus, index) => <BusTableRow admin={admin} bus={bus} index={index} BusHeadings={BusHeadings} key={index}/>)}
        </tbody>
      </table>
    </div>
    </>
  )
}

function BusTableRow({ bus, index, BusHeadings, admin }){
  const [editOpen, setEditOpen] = useState(false)
  const { deleteBus } = useBus()
  const {busNo, busName, driver, driverNo, routeAndTimings, _id} = bus
  
  function handleDelete(){
    deleteBus({ _id })
  }
  
  return (<>
    <tr className="row" key={index}>
      {BusHeadings.map((heading) => <td key={heading} style={{fontSize: "20px"}}>{ bus[heading] }</td>)}
      {admin ? <></> : <> <td><div className="buttons" onClick={() => setEditOpen(true)} style={{color: "black"}}><CreateIcon sx={{ fontSize: 25 }}/></div>
      <div className="buttons" onClick={handleDelete} style={{color: "red"}}><DeleteOutlineIcon sx={{ fontSize: 25 }}/></div></td>
      <Modal open={editOpen} onClose={() => setEditOpen(false)}>
        <BusRegForm submit={() => setEditOpen(false)} data={{busNo, busName, driver, driverNo, routeAndTimings, _id}}/>
      </Modal></>}
    </tr>
  </>)
}

export default BusTable
