import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import "../styles/modal.css"
import ReactDom from "react-dom"

function Modal({ open, children, onClose }) {
  if(!open) return null

  return ReactDom.createPortal(
    <>
      <div className="OVERLAY_STYLES">
        <div className="MODAL_STYLES">
          <div className='CROSS_STYLES'>
            <CloseIcon onClick={onClose} className='closeIcon'/>
          </div>
          <div className="children">
            {children}
          </div>
        </div>
      </div>
    </>,
    document.getElementById("portal")
  )
}

export default Modal
