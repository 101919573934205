import React, { useState } from 'react';
import useStops from '../customhooks/useStops';
import { useSelector } from "react-redux"

function RoutesRegForm({ submit, data }) {
  const [formData, setFormData] = useState(data);
  const [error, setError] = useState("")
  const { addStops } = useStops()
  const loading = useSelector((state) => state.stopState.loading)
  // console.log(formData)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    if(!formData.bpNo || !formData.boardingPoint || !formData.time){
      setError("All fields are mandatory")
      return false
    }
    setError("")
    return true
  }

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(validateForm()){
      await addStops(formData)
      if(!loading){
        submit()
      }
    }
  };

  return (
    <div className='busRegForm'>
      <h2 style={{textAlign: "center", padding: "5px 0px 20px 0px"}}>Stop Registration and Editing Form</h2>
      <form className='busForm' onSubmit={handleSubmit}>
        <div className="inputDataFieldsSingle">
          <h3>Stop No: </h3>
          <input className='regNameInput'
            type="text"
            name="bpNo"
            value={formData.bpNo}
            onChange={handleChange}
            placeholder='Boarding Point Number'
          />
        </div>
        <div className="inputDataFieldsSingle">
          <h3>Stop Name:</h3>
          <input className='regNameInput'
            type="text"
            name="boardingPoint"
            value={formData.boardingPoint}
            onChange={handleChange}
            placeholder='Boarding point Name'
          />
        </div>
        <div className="inputDataFieldsSingle">
          <h3>time:</h3>
          <input className='regNameInput'
            type="text"
            name="time"
            value={formData.time}
            onChange={handleChange}
            placeholder='Boarding Time'
          />
        </div>

        {error && <p style={{ color: 'red', padding: "10px" }}>{error}</p>}
        <button type="submit" id='regSubmit'>Submit</button>
      </form>
    </div>
  );
}

export default RoutesRegForm;
