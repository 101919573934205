import React, { useEffect, useState } from 'react'
import useBus from './../customhooks/useBus';
import "../styles/bustable.css"
import Modal from './Modal';
import { useSelector } from "react-redux"
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import useCluster from '../customhooks/useCluster';
import ClusterRegForm from "./ClusterRegForm"
import useStops from '../customhooks/useStops';
import useStudent from '../customhooks/useStudent';


function Cluster({ admin }) {
  const [isOpen, setOpen] = useState(false)
  const { fetchBuses } = useBus()
  const { fetchClusters } = useCluster()
  const { fetchStops } = useStops()
  const {fetchStudents} = useStudent()
  const clusters = useSelector((state) => state.clusterState.clusters)
    const clusterHeadings = ["cluster", "clusterName"]
    useEffect(() => {
      fetchStops()
      fetchBuses()
      fetchClusters()
      fetchStudents()
    }, [])
  return (
    <>
    <div className="busOperations">
        {admin ? <div></div> : <> <div className='buttons' onClick={() => setOpen(true)} style={{color: "green", padding: "5px 30px", borderRadius: "5px"}}>Add</div>
        <Modal open={isOpen} onClose={() => setOpen(false)}>
            <ClusterRegForm submit={() => setOpen(false)} data={{cluster: "", clusterName: "", busRoutes: []}}/>
        </Modal></>}
    </div>
    <div className='busTable'>
      <table className=''>
        <thead>
            <tr className='row'>
                {clusterHeadings.map((heading, index) => <th key={index} style={{fontSize: "20px"}}>{heading[0].toUpperCase() + heading.slice(1, heading.length)}</th>)}
                {admin ? <></> : <th style={{fontSize: "20px"}}>Actions</th>}
            </tr>
        </thead>
        <tbody>
            {clusters && clusters.map((cluster, index) => <ClusterTableRow admin={admin} clusterDetail={cluster} index={index} clusterHeadings={clusterHeadings} key={index}/>)}
        </tbody>
      </table>
    </div>
    </>
  )
}

function ClusterTableRow({ clusterDetail, index, clusterHeadings, admin }){
  const [editOpen, setEditOpen] = useState(false)
  const { deleteCluster } = useCluster()
  const {cluster, clusterName, busRoutes} = clusterDetail
  
  function handleDelete(){
    deleteCluster({ cluster })
  }
  
  return (<>
    <tr className="row" key={index} >
      {clusterHeadings.map((heading) => <td key={heading} style={{fontSize: "20px"}}>{ clusterDetail[heading] }</td>)}
      {admin ? <></> :  <><td><div className="buttons" onClick={() => setEditOpen(true)} style={{color: "black"}}><CreateIcon sx={{ fontSize: 25 }}/></div>
      <div className="buttons" onClick={handleDelete} style={{color: "red"}}><DeleteOutlineIcon sx={{ fontSize: 25 }}/></div></td>
      <Modal open={editOpen} onClose={() => setEditOpen(false)}>
        <ClusterRegForm submit={() => setEditOpen(false)} data={{cluster, clusterName, busRoutes}}/>
      </Modal></>}
    </tr>
  </>)
}

export default Cluster
