import React from 'react'
import { NavLink } from 'react-router-dom';

function NavComponent({ NavName, routeLink }){
    return (
      <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
        <NavLink to={routeLink} style={{textDecoration: "none", color: "white"}}>
          {NavName}
        </NavLink>
    </div>
    )
}

export default NavComponent
