import React, { useEffect } from 'react'
import "../styles/busroutes.css"
import data from '../Data/data'
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { useSelector } from 'react-redux';
import useBus from '../customhooks/useBus';
import useCluster from '../customhooks/useCluster';
import useStops from '../customhooks/useStops';
import useStudent from '../customhooks/useStudent';

function BusRoutes() {

  const { fetchBuses } = useBus()
  const { fetchClusters } = useCluster()
  const { fetchStops } = useStops()
  const {fetchStudents} = useStudent()
  const { converIdToBus } = useBus()

  useEffect(() => {
    fetchStops()
    fetchBuses()
    fetchClusters()
    fetchStudents()
  }, [])
  
  
  const clusters = useSelector(state => state.clusterState.clusters)
  const arr = clusters.map((cluster) => ([cluster.cluster, cluster.clusterName, cluster.busRoutes.map((busroute) => converIdToBus(busroute).busNo)]))
  // console.log(clusters)
  // const arr = data.map((cluster) => ([cluster.cluster, cluster.clusterName, cluster.busRoutes.map((busroute) => busroute.busNo)]))
  // console.log(arr)
  // console.log("another", another)
  return (
    <>
    <Header />
    <div className='Busroutes'>
      <h1 className='header'>Welcome to CMR Group of Institutions Transport Department</h1>
      <div className="clusterWrapper">
        {arr.map((elem) => <BusRow key={elem[0]} id={elem[0]} place={elem[1]} busNo={elem[2]}/>)}
      </div>
      </div>
    </>
  ) 
}

function BusRow({ id, place }){
  const navigate = useNavigate();
  function handleRouteClick(){
    navigate("/busRoutes/" + id)
  }
  return (
    <div className='clusterRow'>
      <h3 onClick={handleRouteClick}>{place}</h3>
    </div>
  )   
}



export default BusRoutes
