import axios from "axios"
import { useDispatch } from 'react-redux'
import { clusterActions } from "../store/store"
import { failNotify, SuccessNotify } from "../components/notify"
import Url from "../Data/data"

function useCluster() {
    const dispatch = useDispatch()
  // const url = "https://us-central1-cmrgi-transport.cloudfunctions.net/api/cluster"
  const url = Url + "/cluster"
  
  const fetchClusters = async () => {
    dispatch(clusterActions.setLoading(true))
    try{
        const res = await axios.get(url + "/getClusterDetails");
        dispatch(clusterActions.setClusters(res.data.result))
        dispatch(clusterActions.setMessage(res.data.message))
    }
    catch(err){
      failNotify(err.response.data.message)
    }
    finally{
        dispatch(clusterActions.setLoading(false))
    }
  }

  const addCluster = async (newCluster) => {
    dispatch(clusterActions.setLoading(true))
    try{
        const res = await axios.post(url + "/addCluster", newCluster)
        SuccessNotify(res.data.message)
    }
    catch(err){
      failNotify(err.response.data.message)
    }
    finally{
        dispatch(clusterActions.setLoading(false))
        fetchClusters()
    }
  }

  const deleteCluster = async (clusterDetails) => {
    dispatch(clusterActions.setLoading(true))
    try{
        const res = await axios.post(url + "/deleteCluster", clusterDetails)
        SuccessNotify(res.data.message)
    }
    catch(err){
      failNotify(err.response.data.message)
    }
    finally{
        dispatch(clusterActions.setLoading(false))
        fetchClusters()
    }
  }

//   const addBusToCluster = async (clusterDetails) => {
//     dispatch(clusterActions.setLoading(true))
//     try{
//         await axios.post(url + "/addBusToCluster", clusterDetails)
//     }
//     catch(err){
//         dispatch(clusterActions.setError(err))
//     }
//     finally{
//         dispatch(clusterActions.setLoading(false))
//         fetchClusters()
//     }
//   }

//   const deleteBusFromCluster = async (clusterDetails) => {
//     dispatch(clusterActions.setLoading(true))
//     try{
//         await axios.post(url + "/deleteBusFromCluster", clusterDetails)
//     }
//     catch(err){
//         dispatch(clusterActions.setError(err))
//     }
//     finally{
//         dispatch(clusterActions.setLoading(false))
//         fetchClusters()
//     }
//   }

  return { fetchClusters, addCluster, deleteCluster }
}

export default useCluster
