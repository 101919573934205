import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { pendingStudentActions } from "../store/store"
import { failNotify, SuccessNotify } from "../components/notify"
import Url from "../Data/data"

function usePendingStudents(){
    const dispatch = useDispatch()
    const url = Url + "/pendingStudent"

    const fetchStudentsByFilter = async(college) => {
        dispatch(pendingStudentActions.setLoading(true))
        try{
            const res = await axios.post(url + "/getStudentByFilter", college)
            dispatch(pendingStudentActions.setPendingStudents(res.data.result))
        }
        catch(err){
            failNotify(err.response.data.result)
            dispatch(pendingStudentActions.setPendingStudents(err.response.data.result))
        }
        finally{
            dispatch(pendingStudentActions.setLoading(false))
        }
    }

    const addPendingStudent = async(studentDetails) => {
        dispatch(pendingStudentActions.setLoading(true))
        try{
            const res = await axios.post(url + "/addStudent", studentDetails)
            SuccessNotify(res.data.message)
        }
        catch(err){
            failNotify(err.response.data.message)
            dispatch(pendingStudentActions.setError(err.response.data.message))
        }
        finally{
            dispatch(pendingStudentActions.setLoading(false))
        }
    }

    const changeAdminProgress = async(studentDetails, college) => {
      dispatch(pendingStudentActions.setLoading(true))
      try{
        const res = await axios.post(url + "/changeAdminProgress", studentDetails)
        SuccessNotify(res.data.message)
      }
      catch(err){
        failNotify(err.response.data.message)
      }
      finally{
        dispatch(pendingStudentActions.setLoading(false))
        fetchStudentsByFilter(college)
      }
    }

    const deletePendingStudent = async (studentDetials, college) => {
        dispatch(pendingStudentActions.setLoading(true))
        try{
            // console.log(studentDetials, college),
          const res = await axios.post(url + "/deleteStudent", studentDetials)
        //   console.log(res)
          SuccessNotify(res.data.message)
        }catch(err){
          failNotify(err.response.data.message)
        }finally{
          dispatch(pendingStudentActions.setLoading(false))
          fetchStudentsByFilter(college);
        }
      } 

    return { addPendingStudent, deletePendingStudent, fetchStudentsByFilter, changeAdminProgress }
}

export default usePendingStudents