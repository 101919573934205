import { initializeApp } from "firebase/app"

const firebaseConfig = {
  apiKey: "AIzaSyBZd256Y9bxmaLsH__po4zY8_H-ZBMCrtM",
  authDomain: "cmrgi-transport.firebaseapp.com",
  projectId: "cmrgi-transport",
  storageBucket: "cmrgi-transport.appspot.com",
  messagingSenderId: "701677659968",
  appId: "1:701677659968:web:017dd8b3f7994bd7adfca5",
  measurementId: "G-YSHYJE9STW"
};

const app = initializeApp(firebaseConfig);

export { app };
