import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children, college, role }) => { 
    const { loading, isAuthenticated } = useSelector(state => state.adminState)
       if(loading){
        <Loader/>
       }
       if (!isAuthenticated) {
        if(role === "adminStaff"){
          return <Navigate to={`/adminStaff/${(college ? college : (localStorage.getItem("college") !== null ? localStorage.getItem("college") : "cmrcet"))}/login`} replace/>
        }
        else if(role === "admin"){
          return <Navigate to={`/admin/${(college ? college : (localStorage.getItem("college") !== null ? localStorage.getItem("college") : "cmrcet"))}/login`} replace/>
        }
        else if(role === "transportAdmin"){
          return <Navigate to={"/transportAdmin/login"} replace/>
        }
        else if(role === "superAdmin"){
          return <Navigate to = {"/superAdmin/login"} replace />
        }
      //   if(college === "cmrgi"){
      //     return <Navigate to = {"/superAdmin/cmrgi/login"} replace />
      //   }
      //   else if(college === "admin"){
      //     return <Navigate to={"/transportAdmin/login"} replace/>
      //   }
      //   else if(college === ""){
      //     return <Navigate to = {"/adminRoute/cmrtc/login"} replace />
      //   }
      //   return < Navigate to={`/adminRoute/${(college && college !== "cmrgi") ? college : (localStorage.getItem("college") !== null ? localStorage.getItem("college") : "cmrcet")}/login`} replace/>; 
      }
     
      return children;
           
};

export default ProtectedRoute;