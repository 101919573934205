import React, { useState, useEffect } from 'react'
import Images from '../components/Images'
import data from '../Data/data';

function EditRoutes() {
    const [selected, setSelected] = useState("")
    const [selectedCluster, setSelectedCluster] = useState("")
    const [routes, setRoutes] = useState([])
    const [stages, setStages] = useState([])
    const clusters = data.map(cluster => cluster.cluster);
    useEffect(() => {
        const temp = selectedCluster !== "" ? 
        data.filter(cluster => cluster.cluster === selectedCluster)[0].busRoutes.map(route => route.busNo)
        : []
        setRoutes(temp)
    }, [selectedCluster])
    useEffect(() => {
        const temp = selected !== "" ? data.map(cluster => cluster.busRoutes).flat().find(busRoute => busRoute.busNo === selected).routeAndTimings : [];
        setStages(temp)
    }, [selected])
    function handleRouteSelect(e){
        setSelected(e.target.value)
    }
    function handleClusterSelect(e){
        setSelectedCluster(e.target.value)
    }
  return (
    <div>
      <div className="Banner">
            <Images imageLink={"https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/logo.webp"} imageText={"CMRGI Banner"} styles={{height:"120px"}}/>
            <Images imageLink={"https://s3-alpha-sig.figma.com/img/ded3/df3a/cc9323c36713d4f2b2affeeaabdd9d0c?Expires=1721001600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nQZj7SHUBocMEUijJNb5xEC-4qHM~a1tb3YwiNCTsNafY9wfyitNynMJiTfhapfp-5NKiZpF3JqPvmTVf4GiF8M1S9Y3nUiydn-KNnHZ6BKlj0mbmJQmfA3PpuUpT4OPtzX~Ma9ZpOFYRwwyS8tfjJB1FAmY0ZdejVqK260YOlub4BakD~bOjnrQ5lrYhLgLuWGuKsot2H9x5A67rBMUlJway1x7bLsSrjuCoWagCnO8rR48vckQS1rUn7TiLWQj6CQgjFjSNodVww~UKo9bDZZAzRAZE1-ajctWx4KhfoXmKWanB7KMID3UQFVDOL~FPPEiND2ctZyj-iLvHFy0rg__"} imageText={"Certifications and Recognitions"}/>
        </div>
        <div className="inputDataFieldsSingle below">
            <h3>Select Cluster No: </h3>
            <select onChange={handleClusterSelect}>
                <option value="">Select Cluster No</option>
                {clusters.map((route, index) => <option key={index} value={route}>{route}</option>)}
            </select>
        </div>
        <div className="inputDataFieldsSingle below">
            <h3>Route No: </h3>
            <select onChange={handleRouteSelect}>
            {selectedCluster ? 
            <>
                <option value="">Select Route No</option>
                {routes.map((route, index) => <option key={index} value={route}>{route}</option>)}
            </>
             : <option>Select Cluster First</option>
            }
            </select>
        </div>
        <div className="inputDataFieldsSingle below">
            <h3>Stage: </h3>
            <select>
            {selected !== "" ?
            <>
                <option value="">Select stage</option>
                {stages.map((stage, index) => <option key={index} value={stage.boardingPoint} >{stage.boardingPoint}</option>)}
            </> 
                : <option>Select Route First</option>}
            </select>
        </div>
    </div>
  )
}

export default EditRoutes
