import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import useStudent from '../customhooks/useStudent';

function FinanceFilter() {
    const colleges = ["cmrtc", "cmrcet", "cmrit", "cmrec", "cmrcp", "cmrims", "cmrihs"];
    const [departments, setDepartments] = useState(["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE", "MBA"]);
    const years = ["First", "Second", "Third", "Fourth"];
    const routes = useSelector((state) => state.busState.buses)
    const { getStudentByFilter } = useStudent()
    const students = useSelector((state) => state.studentState.tempStudents)
    const studentCount = students.length
    const feeTotal = students.reduce((total, student) => total + student.amountPaid, 0)
    // console.log(studentCount, feeTotal)


    const [formData, setFormData] = useState({
        college : "",
        department: "",
        year: "",
        routeNo: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === "college"){
            const college = value
            if(college === "cmrec"){
                setDepartments(["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE"])
            }
            else if(college === "cmrcet"){
                setDepartments(["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE", "EEE", "MECHANICAL", "CIVIL", "MBA"])
            }
            else if(college === "cmrims"){
                setDepartments(["MBBS"])
            }
            else if(college === "cmrihs"){
                setDepartments(["NURSING", "BPT", "MLT", "ALLIED HEALTH SCIENCES"])
            }
        }
        setFormData(prevState => ({
            ...prevState,
            [name] : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        getStudentByFilter(formData)
    }

    return (
        <div className='Wrapper' style={{ display: "flex", flexDirection: "column"}}>
        <form className='form registerForm' onSubmit={handleSubmit} style={{width: "95%"}}>
                    <div className="container">
                        <h1>Get Finance Data</h1>
                    </div>
                    <div className="inputDataFieldsMultiple">
                        <div className="inputDataFieldsSingle below">
                            <h3>Campus :</h3>
                            <select name="college" value={formData.college} onChange={handleChange}>
                                <option value="">Select College</option>
                                {colleges.map((college, index) => (
                                    <option key={index} value={college}>{college.toUpperCase()}</option>
                                ))}
                            </select>
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>Department:</h3>
                            <select name="department" value={formData.department} onChange={handleChange}>
                                <option value="">Select Department</option>
                                    {(departments.map((dept, index) => (
                                        <option key={index} value={dept}>{dept}</option>
                                    )))}
                            </select>
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>Year:</h3>
                            <select name="year" value={formData.year} onChange={handleChange}>
                                <option value="">Select Year</option>
                                {years.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>Bus:</h3>
                            <select name="routeNo" value={formData.routeNo} onChange={handleChange}>
                                <option value="">Select Bus No</option>
                                {routes.map((route, index) => (
                                    <option key={index} value={route._id}>{route.busNo}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <button type="submit" id='regSubmit' style={{width: "100px"}} >Submit</button>
            <div style={{margin: "20px 10px", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", textAlign: "center", width: "420px"}}>
                <div style={{display: "flex", padding: "10px"}}>
                    <h3>Total Students:</h3>
                    <p>{studentCount}</p>
                </div>
                <div style={{display: "flex", padding: "10px"}}>
                    <h3>Amount Collected:</h3>
                    <p>{feeTotal.toLocaleString('en-IN')}</p>
                </div>
            </div>
            </form>
            </div>
    )
}

export default FinanceFilter
