import React from 'react'
import Header from '../components/Header'
import "../styles/about.css"

function About() {
  return (
    <>
    <Header />
    <div className='Busroutes'>
      <div className='wrapper'>
        <div className="intro box">
          <h2 className='aboutHeading'>Welcome to CMR Group Of Institutions</h2>
          <div className="paraInner">
            <p className='aboutPara'>CMR Group of Institutions is one of the premier educational institutions dedicated to impart quality education and promoting excellence in academic pursuits in the field of Science, Pharmacy, Engineering Management. The Group is based out of Hyderabad, Telangana. It was established under the aegis of MGR education society.</p>
          </div>
        </div>
        <div className="vision box">
          <h2 className='aboutHeading'>Our Vision</h2>
          <div className="paraInner">
            <p className='aboutPara'>To impart Quality Technical Education and to meet the challenges imposed on, by being in sync with the fast changing Technology &amp; Globalization.</p>
          </div>
        </div>
        <div className="mission box">
          <h2 className='aboutHeading'>Our Mission</h2>
          <div className="paraInner missionPara">
              <p className='aboutPara'>To impart value based quality technical education through innovative teaching and learning methods.</p>
              <p className='aboutPara'>To continuously produce employable technical graduates with advanced technical skills to meet the current and future technological needs of the society</p>
              <p className='aboutPara'>To prepare the graduates for higher learning with emphasis on academic and industrial research.</p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default About
