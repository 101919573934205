import React, { useRef, useState } from 'react'
import Webcam from "react-webcam"

function ImageCapture({ submit, upload }) {
    const webRef = useRef(null)
    const [preview, setPreview] = useState(false)
    const [img, setImg] = useState(null)
    const showImage = () => {
        setPreview(true)
        setImg(webRef.current.getScreenshot())
    }
    
    const submitImage = async () => {
        const response = await fetch(img);
        const blob = await response.blob();
        const file = new File([blob], `${Date.now()}.jpg`, { type: "image/jpeg" });
        upload(file)
        submit()
    }
    
    const retakeImage = () => {
        setPreview(false)
        setImg(null)
    }

    const videoConstraints = {
        width: 1000,
        height: 400,
        facingMode: 'user',
      };
  return (
    <div style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
      {preview ? <img src={img}/> : <Webcam audio={false}
        ref={webRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        />}
      {preview ? 
      <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <button onClick={() =>submitImage()} style={{margin: "20px 30px", width: "fit-content"}}> Submit Image </button>
        <button onClick={() =>retakeImage()} style={{margin: "20px 30px", width: "fit-content"}}> Retake Image </button>
      </div>
      :<button onClick={() =>showImage()} style={{margin: "20px 30px", width: "fit-content"}}> Capture Image </button>}
    </div>
  )
}

export default ImageCapture
