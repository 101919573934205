import React, { useEffect, useState } from 'react';
import Header from './../components/Header';
import "../styles/transportrequestform.css";
import ShowStudentDetails from '../components/ShowStudentDetails';
import Modal from '../components/Modal';
import { useSelector } from 'react-redux';
import useBus from './../customhooks/useBus';
import useStops from './../customhooks/useStops';
import { app } from "../Data/firebaseConfiguration"
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import ImageCapture from '../components/ImageCapture';

function TransportRequestForm({ college }) {
    const [formData, setFormData] = useState({
        college: college,
        studentName: "",
        fatherName: "",
        rollNo: "",
        phoneNumber: "",
        guardianNumber: "",
        department: "",
        year: "",
        hNo: "",
        street: "",
        city: "",
        district: "",
        state: "",
        routeNo: "",
        stage: "",
        amountPaid: "",
        _id: "",
        status: "pending",
        adminRemarks: ""
    });

    const [file, setFile] = useState(null)
    const [imageProgress, setImageProgress] = useState(null)
    const [isOpen, setOpen] = useState(false)
    const [imageCaptureisOpen, setImageCaptureisOpen] = useState(false)
    const [stages, setStages] = useState([]);
    const [bus, setBus] = useState([])
    const stops = useSelector((state) => state.stopState.stops)
    const { fetchBuses } = useBus()
    const { fetchStops } = useStops()
    let departments = ["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE", "MBA"];
    const years = ["First", "Second", "Third", "Fourth"];
    if(college === "cmrec"){
        departments = ["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE"]
    }
    else if(college === "cmrcet"){
        departments = ["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE", "EEE", "MECHANICAL", "CIVIL", "MBA"]
    }
    else if(college === "cmrims"){
        departments = ["MBBS"]
    }
    else if(college === "cmrihs"){
        departments = ["NURSING", "BPT", "MLT", "ALLIED HEALTH SCIENCES"]
    }
    const routes = useSelector((state) => state.busState.buses)

    useEffect(() => {
        fetchBuses()
        fetchStops()
    }, [])

    useEffect(() => {
        if(bus.length > 0){
            const check = new Map()
            for(const stop of stops){
                check.set(stop._id, stop)
            }
            const temp = [{boardingPoint: "select Stage", _id: "1"}]
            for(const stop of bus){
                if(check.has(stop)) temp.push(check.get(stop))
            }
            setStages(temp);
        }
        else{
            setStages([{boardingPoint: "No stops under this bus", _id: "1"}])
        }
    }, [formData.routeNo])

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        files ? setFile(files[0]) :  
        setFormData(prevState => ({
            ...prevState,
            [name] : value
        }));
    };

    const handleRouteSelect = (e) => {
        const selectedRoute = e.target.value;
        const temp = routes.filter((bus) => bus._id === selectedRoute)
        // const sangareddyStops = ["35", "35A", "36", "36A"]
        // if(sangareddyStops.includes(temp[0].busNo)){
        //     setFormData(prevState => ({
        //         ...prevState,
        //         amountPaid: 50000
        //     }))
        // }
        // else{
        //     setFormData(prevState => ({
        //         ...prevState,
        //         amountPaid: 45000
        //     }))
        // }
        setBus(temp[0] !== undefined ? temp[0].routeAndTimings: [])
        setFormData(prevState => ({
            ...prevState,
            routeNo: selectedRoute,
            stage: ""
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpen(true)
        // console.log("Form Data Submitted: ", formData, file);
    };

    const handleFileUpload = (e) => {
        const {files} = e.target
        const GetFile = files[0]
        // console.log(GetFile)
        const storage = getStorage(app)
        const fileName = new Date().getTime() + GetFile ? GetFile.name : ""
        const storageRef = ref(storage, 'images/' + fileName);
        const uploadTask = uploadBytesResumable(storageRef, GetFile);

        uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setImageProgress(Math.round(progress));
              switch (snapshot.state) {
                case 'paused':
                //   console.log('Upload is paused');
                  break;
                case 'running':
                //   console.log('Upload is running');
                  break;
                default: break;
              }
            }, 
            (error) => {
              switch (error.code) {
                case 'storage/unauthorized':
                  break;
                case 'storage/canceled':
                  break;
                case 'storage/unknown':
                  break;
              }
            }, 
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setFile(downloadURL);
              });
            }
          );
    }

    const handleImageCapture = (GetFile) => {
        const storage = getStorage(app)
        const fileName = GetFile.name
        const storageRef = ref(storage, 'images/' + fileName);
        const uploadTask = uploadBytesResumable(storageRef, GetFile);

        uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setImageProgress(Math.round(progress));
              switch (snapshot.state) {
                case 'paused':
                //   console.log('Upload is paused');
                  break;
                case 'running':
                //   console.log('Upload is running');
                  break;
                default: break;
              }
            }, 
            (error) => {
              switch (error.code) {
                case 'storage/unauthorized':
                  break;
                case 'storage/canceled':
                  break;
                case 'storage/unknown':
                  break;
              }
            }, 
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setFile(downloadURL);
                // console.log(downloadURL)
              });
            }
          );
    }

    return (
        <>
            <Header />
            <Modal open={isOpen} onClose={() => setOpen(false)}>
                <ShowStudentDetails submit={() => setOpen(false)} studentDetails={formData} show={true} photo={file} />
            </Modal>
            <Modal open={imageCaptureisOpen} onClose={() => setImageCaptureisOpen(false)}>
                <ImageCapture submit={() => setImageCaptureisOpen(false)} upload={handleImageCapture} />
            </Modal>
            <div className="Wrapper">
                <form className='form registerForm' onSubmit={handleSubmit}>
                    <div className="container">
                        <h1>Student Registration Form</h1>
                    </div>
                    <div className="inputDataFieldsSingle">
                        <h3>Select College :</h3>
                        {/* <select name="college" value={formData.college} onChange={handleChange}>
                            <option value="">Select College</option>
                            {colleges.map((college, index) => (
                                <option key={index} value={college}>{college}</option>
                            ))}
                        </select> */}
                        <select name="college" value={formData.college}>
                            <option value={college}>{college.toUpperCase()}</option>    
                        </select>
                    </div>
                    <div className="inputDataFieldsSingle">
                        <h3>Student Name :</h3>
                        <input
                            className='regNameInput'
                            type="text"
                            name="studentName"
                            value={formData.studentName}
                            onChange={handleChange}
                            placeholder='Enter Student Name'
                        />
                    </div>
                    <div className="inputDataFieldsSingle">
                        <h3>Father/Guardian Name :</h3>
                        <input
                            className='regNameInput'
                            type="text"
                            name="fatherName"
                            value={formData.fatherName}
                            onChange={handleChange}
                            placeholder='Enter Guardian Name'
                        />
                    </div>
                    <div className="inputDataFieldsSingle">
                        <h3>Roll No:</h3>
                        <input
                            className='regNameInput'
                            type="text"
                            name="rollNo"
                            value={formData.rollNo}
                            onChange={handleChange}
                            placeholder='Enter Roll no'
                        />
                    </div>
                    <div className="inputDataFieldsSingle">
                        <h3>Phone Number :</h3>
                        <input
                            className='regNameInput'
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder='Enter Phone number'
                            pattern='[0-9]{10}'
                        />
                    </div>
                    <div className="inputDataFieldsSingle">
                        <h3>Father/Guardian Number :</h3>
                        <input
                            className='regNameInput'
                            type="tel"
                            name="guardianNumber"
                            value={formData.guardianNumber}
                            onChange={handleChange}
                            placeholder='Enter Guardian Phone number'
                            pattern='[0-9]{10}'
                        />
                    </div>
                    <div className="inputDataFieldsSingle">
                        <h3>Upload Image:</h3>
                        <input
                            type="file"
                            accept="image/*"
                            name="file"
                            onChange={handleFileUpload}
                            style={{width: "fit-content", padding: '2px 20px'}}
                        />
                        <input type='button' value={"Capture Image"}  style={{width: "fit-content", padding: '2px 5px'}} onClick={() => setImageCaptureisOpen(true)}/>
                        <input type='button' value={"Delete Image"}  style={{width: "fit-content", padding: '2px 5px'}} onClick={() => {setFile(null); setImageProgress(null)}}/>
                    </div>
                    {imageProgress !== null && (!file)  ? <p style={{textAlign: "center"}}>Uploading { imageProgress }%</p> : <p style={{display: "none"}}></p>}
                    {file ? <> <h3 style={{textAlign: "center", fontSize: "20px", padding: "20px"}}>Image Preview</h3> <img src={file} style={{width: "240px", height: "240px"}}/> </> : <> <h3 style={{textAlign: "center", fontSize: "20px", padding: "20px"}}>Image Preview</h3> <div style={{width: "240px", height: "240px"}}/> </>}

                    <div className="inputDataFieldsMultiple">
                        <div className="inputDataFieldsSingle">
                            <h3>Branch:</h3>
                            <select name="department" value={formData.department} onChange={handleChange}>
                                <option value="">Select Department</option>
                                {departments.map((branch, index) => (
                                    <option key={index} value={branch}>{branch}</option>
                                ))}
                            </select>
                        </div>
                        <div className="inputDataFieldsSingle">
                            <h3>Year:</h3>
                            <select name="year" value={formData.year} onChange={handleChange}>
                                <option value="">Select Year</option>
                                {years.map((year, index) => (
                                    <option key={index} value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <h3>Address:</h3>
                    <div className="inputDataFieldsMultiple">
                        <div className="inputDataFieldsSingle below">
                            <h3>HNo:</h3>
                            <input
                                className='regNameInput'
                                type="text"
                                name="hNo"
                                value={formData.hNo}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>Street:</h3>
                            <input
                                className='regNameInput'
                                type="text"
                                name="street"
                                value={formData.street}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>Village/Mandal/City:</h3>
                            <input
                                className='regNameInput'
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>District:</h3>
                            <input
                                className='regNameInput'
                                type="text"
                                name="district"
                                value={formData.district}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>State:</h3>
                            <input
                                className='regNameInput'
                                type="text"
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="inputDataFieldsMultiple">
                        <div className="inputDataFieldsSingle below">
                            <h3>Route No:</h3>
                            <select name="routeNo" value={formData.routeNo} onChange={handleRouteSelect}>
                                <option value="">Select Route No</option>
                                {routes.map((route, index) => (
                                    <option key={index} value={route._id}>{route.busNo}</option>
                                ))}
                            </select>
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>Stage:</h3>
                            <select name="stage" value={formData.stage} onChange={handleChange}>
                                {formData.routeNo !== "" ?
                                    (stages.map((stage, index) => (
                                        <option key={index} value={stage._id}>{stage.boardingPoint}</option>
                                    )))
                                    : <option>Select Route First</option>}
                            </select>
                        </div>
                        <div className="inputDataFieldsSingle below">
                            <h3>Amount Paid:</h3>
                            <input
                                className='regNameInput'
                                type="text"
                                name="amountPaid"
                                value={formData.amountPaid}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="inputDataFieldsMultiple">
                        <div className="inputDataFieldsSingle below">
                            <h3>Remarks:</h3>
                            <input
                                className='regNameInput'
                                type="text"
                                name="remarks"
                                value={formData.remarks}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <button type="submit" id='regSubmit'>Request Admin</button>
                </form>
            </div>
        </>
    );
}

export default TransportRequestForm;


// import React, { useEffect, useState } from 'react'
// import Header from './../components/Header';
// import "../styles/transportrequestform.css"
// import data from '../Data/data';


// function TransportRequestForm() {
//     const [selected, setSelected] = useState("")
//     const [stages, setStages] = useState([])
//     const colleges = ["CMRTC", "CMRCET", "CMRIT", "CMREC", "CMRCP"];
//     const departments = ["CSM", "AIML", "CSE", "CSD", "CSG", "IT", "ECE"]
//     const years = ["First", "Second", "Third", "Fourth"]
//     const routes = data.map(cluster => cluster.busRoutes).flat().map(busRoute => busRoute.busNo)
//     useEffect(() => {
//         const temp = selected !== "" ? data.map(cluster => cluster.busRoutes).flat().find(busRoute => busRoute.busNo === selected).routeAndTimings : [];
//         setStages(temp)
//     }, [selected])
//     function handleRouteSelect(e){
//         setSelected(e.target.value)
//     }
//   return (
//     <>
//         <Header />
//         <div className="Wrapper">
//             <form className='form registerForm'>
//                 <div className="container">
//                     <h1>Student Registration Form</h1>
//                 </div>
//                 <div className="inputDataFieldsSingle">
//                     <h3>Select College : </h3>
//                     <select>
//                         <option value="">Select College</option>
//                         {colleges.map((college, index) => <option key={index} value={college}>{college}</option>)}
//                     </select>
//                 </div>
//                 <div className="inputDataFieldsSingle">
//                     <h3>Student Name : </h3>
//                     <input className='regNameInput' type="text" name="studentName" id="studentName" placeholder='Enter Student Name' />
//                 </div>
//                 <div className="inputDataFieldsSingle">
//                     <h3>Father/Gaurdian Name : </h3>
//                     <input className='regNameInput' type="text" name="fatherName" id="fatherName" placeholder='Enter Gaurdian Name' />
//                 </div>
//                 <div className="inputDataFieldsSingle">
//                     <h3>Roll No: </h3>
//                     <input className='regNameInput' type="text" name="rollno" id="rollno" placeholder='Enter Roll no' />
//                 </div>
//                 <div className="inputDataFieldsSingle">
//                     <h3>Phone Number : </h3>
//                     <input className='regNameInput' type="tel" name="phoneNumber" id="phoneNumber" placeholder='Enter Phone number' pattern='[0-9]{10}' />
//                 </div>
//                 <div className="inputDataFieldsSingle">
//                     <h3>Upload Image: </h3>
//                     <input type="file" accept="image/*"/>
//                 </div>
//                 <div className="inputDataFieldsMultiple">
//                     <div className="inputDataFieldsSingle">
//                         <h3>Branch: </h3>
//                         <select>
//                             <option value="">Select Department</option>
//                             {departments.map((branch, index) => <option key={index} value={branch}>{branch}</option>)}
//                         </select>
//                     </div>
//                     <div className="inputDataFieldsSingle">
//                         <h3>Year: </h3>
//                         <select>
//                             <option value="">Select Year</option>
//                             {years.map((year, index) => <option key={index} value={year}>{year}</option>)}
//                         </select>
//                     </div>
//                 </div>
//                 <h3>Address: </h3>
//                 <div className="inputDataFieldsMultiple">
//                     <div className="inputDataFieldsSingle below">
//                         <h3>HNo: </h3>
//                         <input className='regNameInput' type="text" name="" id="" />
//                     </div>
//                     <div className="inputDataFieldsSingle below">
//                         <h3>street: </h3>
//                         <input className='regNameInput' type="text" name="" id="" />
//                     </div>
//                     <div className="inputDataFieldsSingle below">
//                         <h3>Village/Mandal/City: </h3>
//                         <input className='regNameInput' type="text" name="" id="" />
//                     </div>
//                     <div className="inputDataFieldsSingle below">
//                         <h3>District: </h3>
//                         <input className='regNameInput' type="text" name="" id="" />
//                     </div>
//                     <div className="inputDataFieldsSingle below">
//                         <h3>State: </h3>
//                         <input className='regNameInput' type="text" name="" id="" />
//                     </div>
//                 </div>
//                 <div className="inputDataFieldsMultiple">
//                     <div className="inputDataFieldsSingle below">
//                         <h3>Route No: </h3>
//                         <select onChange={handleRouteSelect}>
//                             <option value="">Select Route No</option>
//                             {routes.map((route, index) => <option key={index} value={route}>{route}</option>)}
//                         </select>
//                     </div>
//                     <div className="inputDataFieldsSingle below">
//                         <h3>Stage: </h3>
//                         <select>
//                         {selected !== "" ? 
//                             (stages.map((stage, index) => <option key={index} value={stage.boardingPoint} >{stage.boardingPoint}</option>))
//                             : <option>Select Route First</option>}
//                         </select>
//                     </div>
//                     <div className="inputDataFieldsSingle below">
//                         <h3>Amount Paid: </h3>
//                         <input className='regNameInput' type="text" name="" id="" />
//                     </div>
//                 </div>
//                 <button type="submit" id='regSubmit'>Submit</button>
//             </form> 
//         </div>
//     </>
//   )
// }

// export default TransportRequestForm
