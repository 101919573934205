import React, { useEffect, useState } from 'react';
import useBus from './../customhooks/useBus';
import { useSelector } from 'react-redux';
import useCluster from '../customhooks/useCluster';

function ClusterRegForm({ submit, data }) {
  const [formData, setFormData] = useState(data);
  const [searchText, setSearchText] = useState("")
  const [error, setError] = useState('');
  const buses = useSelector((state) => state.busState.tempBuses)
  const { searchBuses } = useBus()
  const { addCluster } = useCluster()
  const loading = useSelector((state) => state.clusterState.loading)

  useEffect(() => {
    searchBuses(searchText)
  }, [searchText])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      if(checked){
        return {
          ...prevData,
          busRoutes: [...prevData.busRoutes, value]
        }
      }
      else{
        return {
          ...prevData,
          busRoutes: prevData.busRoutes.filter((bus) => bus !== value)
        }
      }
    });
  };

  const validateForm = () => {
    if (!formData.cluster || !formData.clusterName ) {
      setError('All fields except buses are required.');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await addCluster(formData)
      if(!loading){
        submit()
      }
    }
  };

  const handleSearchBar = (e) =>{
    setSearchText(e.target.value)
  }

  return (
    <div className='busRegForm'>
      <h2 style={{textAlign: "center", padding: "5px 0px 20px 0px"}}>Bus Registration and Editing Form</h2>
      <form className='busForm' onSubmit={handleSubmit}>
        <div className="inputDataFieldsSingle">
          <h3>Cluster No: </h3>
          <input className='regNameInput'
            type="text"
            name="cluster"
            value={formData.cluster}
            onChange={handleChange}
            placeholder='Cluster'
          />
        </div>
        <div className="inputDataFieldsSingle">
          <h3>Cluster Name:</h3>
          <input className='regNameInput'
            type="text"
            name="clusterName"
            value={formData.clusterName}
            onChange={handleChange}
            placeholder='Cluster Name'
          />
        </div>

        <h3 style={{marginTop: "30px"}}>Select Buses :</h3>
        <input
            className='regNameInput'
            type="text"
            name="searchText"
            value={searchText}
            onChange={handleSearchBar}
            placeholder='Enter Bus to Search'
          />
        <div className='stopsTable'>
          {(buses && buses.length > 0) ? (buses.map((bus) => (
            <div key={bus._id} className='stopsCheckBox'>
                <input
                    className='CheckBoxListView'
                    type="checkbox"
                    value={bus._id}
                    checked={formData.busRoutes.includes(bus._id)}
                    onChange={handleCheckboxChange}
                    />
                <h4 key={bus} style={{padding: "0px 0px 0px 20px"}}>{bus.busName}</h4>
            </div>
          ))):
          (<h4>No Buses to show</h4>)
        }
        </div>

        {error && <p style={{ color: 'red', padding: "10px" }}>{error}</p>}

        <button type="submit" id='regSubmit'>Submit</button>
      </form>
    </div>
  );
}

export default ClusterRegForm;
