import React, { useEffect, useState } from 'react';
import useBus from './../customhooks/useBus';
import { useSelector } from 'react-redux';
import useStops from '../customhooks/useStops';

function BusRegForm({ submit, data }) {
  const [formData, setFormData] = useState(data);
  const [searchText, setSearchText] = useState("")
  const [error, setError] = useState('');
  const stops = useSelector((state) => state.stopState.tempStops)
  const { addBus } = useBus()
  const { searchStops } = useStops()
  const loading = useSelector((state) => state.busState.loading)

  useEffect(() => {
    searchStops(searchText)
  }, [searchText])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevData) => {
      if(checked){
        return {
          ...prevData,
          routeAndTimings: [...prevData.routeAndTimings, value]
        }
      }
      else{
        return {
          ...prevData,
          routeAndTimings: prevData.routeAndTimings.filter((stop) => stop !== value)
        }
      }
    });
  };

  const validateForm = () => {
    if (!formData.busNo || !formData.busName || !formData.driver || !formData.driverNo) {
      setError('All fields except stops are required.');
      return false;
    }
    if (formData.driverNo.length !== 10) {
      setError('Bus-Incharge Number must be 10 digits.');
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      await addBus(formData)
      if(!loading){
        submit()
      }
    }
  };

  const handleSearchBar = (e) =>{
    setSearchText(e.target.value)
  }

  return (
    <div className='busRegForm'>
      <h2 style={{textAlign: "center", padding: "5px 0px 20px 0px"}}>Bus Registration and Editing Form</h2>
      <form className='busForm' onSubmit={handleSubmit}>
        <div className="inputDataFieldsSingle">
          <h3>Bus No: </h3>
          <input className='regNameInput'
            type="text"
            name="busNo"
            value={formData.busNo}
            onChange={handleChange}
            placeholder='BusNo'
          />
        </div>
        <div className="inputDataFieldsSingle">
          <h3>Bus Name:</h3>
          <input className='regNameInput'
            type="text"
            name="busName"
            value={formData.busName}
            onChange={handleChange}
            placeholder='Bus Name'
          />
        </div>
        <div className="inputDataFieldsSingle">
          <h3>Bus-Incharge:</h3>
          <input className='regNameInput'
            type="text"
            name="driver"
            value={formData.driver}
            onChange={handleChange}
            placeholder='Bus-Incharge Name'
          />
        </div>
        <div className="inputDataFieldsSingle">
          <h3>Bus-Incharge Number:</h3>
          <input
            className='regNameInput'
            type="text"
            name="driverNo"
            value={formData.driverNo}
            onChange={handleChange}
            placeholder='Bus-Incharge Number'
          />
        </div>

        <h3 style={{marginTop: "30px"}}>Select Stops :</h3>
        <input
            className='regNameInput'
            type="text"
            name="searchText"
            value={searchText}
            onChange={handleSearchBar}
            placeholder='Enter Stop to Search'
          />
        <div className='stopsTable'>
          {(stops && stops.length > 0) ? (stops.map((stop) => (
            <div key={stop._id} className='stopsCheckBox'>
                <input
                    className='CheckBoxListView'
                    type="checkbox"
                    value={stop._id}
                    checked={formData.routeAndTimings.includes(stop._id)}
                    onChange={handleCheckboxChange}
                    />
                <h4 key={stop} style={{padding: "0px 0px 0px 20px"}}>{stop.boardingPoint}</h4>
            </div>
          ))):
          (<h4>No stops to show</h4>)
        }
        </div>

        {error && <p style={{ color: 'red', padding: "10px" }}>{error}</p>}

        <button type="submit" id='regSubmit'>Submit</button>
      </form>
    </div>
  );
}

export default BusRegForm;
