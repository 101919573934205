import React from 'react'
import NavComponent from './NavComponent';
import "../styles/footer.css"

function Footer() {
  return (
    <div className='footer' style={{padding: "50px 0px 0px 0px"}}>
      <div className="footerRow">
        <div className="footerBlocks">
            <div className="footerBlockHeader">
                <h4>About CMRGI</h4>
            </div>
            <div className="footerPara">
                <p>CMR Group of Institutions is one of the premier educational institutions dedicated to impart quality education and promoting excellence in academic pursuits in the field of Science, Pharmacy, Engineering and Technology and Management. Established under the aegis of MGR Educational Society.</p>
            </div>
        </div>
        <div className="footerBlocks">
            <div className="footerBlockHeader">
            <h4> Quick Links</h4>
            </div>
            <div className="footerPara">
                <NavComponent routeLink={"/about"} NavName={"About CMRGI"} />
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"https://cmrgroup.edu.in/enquiry"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}> Admission Enquiry </a>
                </div>
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"https://cmrgroup.edu.in/placementgraph/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>Placements </a>
                </div>
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"https://cmrgroup.edu.in/courseandintake/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>Courses And Intakes </a>
                </div>
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"https://cmrgroup.edu.in/ebrochure/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>e-Broucher</a>
                </div>
            </div>
        </div>
        <div className="footerBlocks">
            <div className="footerBlockHeader">
                <h4>Our Colleges</h4>
            </div>
            <div className="footerPara">
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"http://cmrcet.ac.in/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>CMR College of Engineering & Technology</a>
                </div>
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"http://www.cmritonline.ac.in/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>CMR Institute of Technology</a>
                </div>
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"http://cmrtc.ac.in/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>CMR Technical Campus</a>
                </div>
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"http://cmrec.ac.in/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>CMR Engineering College</a>
                </div>
                <div className="navComponent" style={{width: "fit-content", padding: "5px 0px", cursor: "pointer"}}>
                    <a href={"http://cmrcp.ac.in/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "white"}}>CMR College of Pharmacy</a>
                </div>
            </div>
        </div>
        <div className="footerBlocks">
            <div className="footerBlockHeader">
                <h4>CONTACT US</h4>
            </div>
            <div className="footerPara">
                <NavComponent routeLink={"/"} NavName={"Kandlakoya(V), Medchal Road"} />
                <NavComponent routeLink={"/"} NavName={" Hyderabad -501401, Telangana, INDIA."} />
                <NavComponent routeLink={"/"} NavName={"Mobile no :  +91 9100760559, +91 9100470559, +91 9247033441, +91 9247033440"} />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
