import React from 'react'
import ReactDom from "react-dom"
import "../styles/loader.css"

const Loader = () => {
  return ReactDom.createPortal(
    <div className="loading">
        <div></div>
    </div>,
    document.getElementById("loader")
  )
}

export default Loader