import React, { cloneElement, useEffect } from 'react'
import "../styles/homepage.css"
import Images from '../components/Images';
import Header from '../components/Header';
import Footer from './../components/Footer';
import useStudent from '../customhooks/useStudent';
import useStops from '../customhooks/useStops';
import useCluster from '../customhooks/useCluster';
import useBus from '../customhooks/useBus';
import HomePageImage from "../images/HomePage.jpg"
import AlliedSciences from "../images/AlliedSciences.jpg"
import Bus from "../images/Bus.svg"
import BusStop from "../images/BusStop.svg"
import People from "../images/People.svg"
import Area from "../images/Area.svg"



function HomePage() {

  const collegeData = [
    {
      collegeLink: "http://cmrcet.ac.in/",
      collegeImage: "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/image.jpg",
      collegeName: "CMR College of Engineering & Technology",
      heading: "CMR College of Engineering & Technology", 
      approval: "UGC AUTONOMOUS", 
      certifications: ["NIRF Rank Band 201-250", "Approved By AICTE", "Accredited by NAAC", "Accredited by NBA"], 
      entrances: "EAMCET/ECET/ICET/PGECET CODE:", 
      code: "CMRK"
    }, 
    {
      collegeLink: "https://cmrithyderabad.edu.in/",
      collegeImage: "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/image1.jpg",
      collegeName: "CMR institute of technology",
      heading: "cmr institute of technology", 
      approval: "UGC AUTONOMOUS", 
      certifications: ["NIRF Rank Band 201-250", "Approved By AICTE", "Accredited by NAAC", "Accredited by NBA"], 
      entrances: "EAMCET/ECET/ICET/PGECET CODE:", 
      code: "CMRM"
    }, 
    {
      collegeLink: "https://cmrcp.ac.in/",
      collegeImage: "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/image2.jpg",
      collegeName: "CMR College of pharmacy",
      heading: "CMR college of pharmacy", 
      approval: "Approved by PCI", 
      certifications: ["NIRF Rank 72", "Permanently Affliated to JNTUH", "Accredited by NBA"], 
      entrances: "EAMCET CODE:", 
      code: "CMRP"
    }, 
    {
      collegeLink: "http://cmrtc.ac.in/",
      collegeImage: "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/image3.jpg",
      collegeName: "CMR technical campus",
      heading: "CMR technical campus", 
      approval: "UGC AUTONOMOUS", 
      certifications: ["NIRF Rank Band 201-250", "Approved By AICTE", "Accredited by NAAC", "Accredited by NBA"], 
      entrances: "EAMCET/ECET/ICET/PGECET CODE:", 
      code: "CMRG"
    }, 
    {
      collegeLink: "http://cmrec.ac.in/",
      collegeImage: "https://cmrgroup.edu.in/wp-content/themes/cmrgrouptheme/assets/image4.jpg",
      collegeName: "CMR engineering college",
      heading: "CMR engineering college", 
      approval: "UGC AUTONOMOUS", 
      certifications: ["NIRF Rank Band 251-300", "Approved By AICTE", "Accredited by NAAC", "Accredited by NBA"], 
      entrances: "EAMCET/ECET/ICET/PGECET CODE:", 
      code: "CMRN"
    }, 
    {
      collegeLink: "https://cmrims.co.in/",
      collegeImage: AlliedSciences,
      collegeName: "CMR INSTITUTE OF MEDICAL SCIENCES And Allied sciences",
      heading: "CMR INSTITUTE OF MEDICAL SCIENCES and allied sciences", 
      approval: "", 
      certifications: ["Approved By NMC", "Affliated to KNRUHS"], 
      entrances: "", 
      code: ""
    }, 
  ]

  const data = [{
    count: 65,
    heading: "Buses",
    style: {
      backgroundColor: "rgba(252, 137, 2, .9)"
    },
    image: Bus
  }, 
  {
    count: 30,
    heading: "Areas Covered",
    style: {
      backgroundColor: "rgba(40, 167, 69, .9)",
    },
    image: Area 
  },
  {
    count: 500,
    heading: "Boarding Point",
    style: {
      backgroundColor: "rgba(220, 53, 69, .9)"
    },
    image: BusStop
  }, 
  {
    count: 4000,
    heading: "Daily Bus Connects",
    style: {
      backgroundColor: "rgba(252, 137, 2, .9)"
    },
    image: People
  }]

  const { fetchBuses } = useBus()
  const { fetchClusters } = useCluster()
  const { fetchStops } = useStops()
  const {fetchStudents} = useStudent()
    useEffect(() => {
      fetchStops()
      fetchBuses()
      fetchClusters()
      fetchStudents()
    }, [])

  return (
    <div className='HomePage'>
        <Header />
        <div className="Content">
            <Images imageLink={HomePageImage} imageText={"Banner"} styles={{ height : "auto", width : "100%", opacity: "0.8"}} />
        </div>
        <div className="grid-container">
          <div className="grid">
            {data.map((individualData, index) => <StatsTile image={individualData.image} count={individualData.count} heading={individualData.heading} style={individualData.style} />)}
          </div>
        </div>
        <div className='collegeGridContainer'>
          <div className="collegeGrid">
            <CollegeTile heading={"Our Group Colleges"} approval={"CMR Group of Institutions"} certifications={[]} />
            {collegeData.map((college, index) => 
              <CollegeTile key={index} collegeLink={college.collegeLink} collegeImage={college.collegeImage} collegeName={college.collegeName.toUpperCase()} approval={college.approval} certifications={college.certifications} code={college.code} entrances={college.entrances} heading={college.heading.toUpperCase()}  ></CollegeTile>

            )}
          </div>
        </div>
        <Footer />
        <div style={{display: "none"}}>
          Developer Details: 
          Name: Nellutla praneeth kumar
          Phone: 7893312873
          email: praneeth.nellutla16@gmail.com
        </div>
    </div>
  )
}


function StatsTile({ count, heading, style, image }) {
  return (
    <div style={{backgroundColor: style.backgroundColor, padding: "10px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent : "center", textAlign: "center", minWidth: "150px"}}>
      <div className="imagesvg">
        <img src={image} alt="icon" style={{width: "60px", height: "60px", padding: "5px"}}/>
      </div>
      <h2>{count}+</h2>
      <p>{heading}</p>
    </div>
  )
}


function CollegeTile({ collegeLink, collegeImage, collegeName, heading, approval, certifications, entrances, code }){
return (
  <a href={collegeLink} target='_blank' rel='noopener' className='collegetile'>
  {collegeImage ? 
  <div className="collegetileleft">
    <Images imageLink={collegeImage} imageText={collegeName}/>
  </div> 
  : <div> <div style={{backgroundColor: "orange", height: "60px", width: "10px"}}></div> </div>}
  <div className="collegetileright">
    <h3 className='collegeh3' style={{fontSize: "12px"}}>{heading}</h3>
    <div className="para" style={{fontSize: "11px"}}>
      <p className='collegep' style={{fontWeight: "bold"}}>{approval}</p>
      <ul className='collegeul'>
        {certifications.map(certificate => <li style={{padding: "3px"}} key={certificate}>{certificate}</li>)}
      </ul>
      <p className='collegep'>{entrances} : <h3 style={{color: "#f78320", display: "inline"}}>{code}</h3></p>
    </div>
  </div>
  </a>
)
}

export default HomePage
