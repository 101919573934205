import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"
import "../styles/bustable.css"
import Modal from './Modal';
import useStops from '../customhooks/useStops';
import RoutesRegForm from './RoutesRegForm';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function Routes({ admin }) {
  const [isOpen, setOpen] = useState(false)
  const stops = useSelector((state) => state.stopState.stops)
  const { fetchStops } = useStops()
    const stopHeadings = ["bpNo", "boardingPoint", "time"]
    // console.log(stops)

    useEffect(() => {
      fetchStops()
    }, [])

  return (
    <>
    <div className="busOperations">
    {admin ? <div></div> : <><div className='buttons' onClick={() => setOpen(true)} style={{color: "green", padding: "5px 30px", borderRadius: "5px"}}>Add</div>
        <Modal open={isOpen} onClose={() => setOpen(false)}>
            <RoutesRegForm submit={() => setOpen(false)} data={{bpNo : "", boardingPoint: "", time: ""}}/>
        </Modal></>}
    </div>
    <div className='busTable'>
      <table className=''>
        <thead>
            <tr className='row'>
                {stopHeadings.map((heading, index) => <th key={index} style={{fontSize: "20px"}}>{heading[0].toUpperCase() + heading.slice(1, heading.length)}</th>)}
                {admin ? <></> : <th style={{fontSize: "20px"}}>Actions</th>}
            </tr>
        </thead>
        <tbody>
            {stops && stops.map((stop, index) => <StopsTableRow admin={admin} stop={stop} index={index} stopHeadings={stopHeadings} key={index}/>)}
        </tbody>
      </table>
    </div>
    </>
  )
}

function StopsTableRow({ stop, index, stopHeadings, admin }){
  const [editOpen, setEditOpen] = useState(false)
  const {bpNo, boardingPoint, time} = stop
  const { deleteStop} = useStops()

  function handleDelete() {
    deleteStop({ bpNo })
  }
  return (
    <tr className="row" key={index}>
      {stopHeadings.map((heading) => <td key={heading} style={{fontSize: "20px"}}>{stop[heading]}</td>)}
      {admin ? <></> : <><td><div className="buttons" onClick={() => setEditOpen(true)} style={{color: "black"}}><CreateIcon sx={{ fontSize: 25 }}/></div>
      <div className="buttons" onClick={handleDelete} style={{color: "red"}}><DeleteOutlineIcon sx={{ fontSize: 25 }}/></div></td>
      <Modal open={editOpen} onClose={() => setEditOpen(false)}>
        <RoutesRegForm submit={() => setEditOpen(false)} data={{bpNo, boardingPoint, time}}/>
      </Modal></>}
    </tr>
  )
  
}

export default Routes
