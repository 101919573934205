import React, { useState } from 'react'
import Images from './../components/Images';
import "../styles/loginpage.css"
import { useNavigate } from 'react-router-dom';
import useAdmin from '../customhooks/useAdmin';
import { useDispatch, useSelector } from 'react-redux';
import { jwtDecode } from 'jwt-decode';
import { adminActions } from '../store/store';

function LoginPage({ collegeBanner, college, role }) {
    const dispatch = useDispatch()
    const { login } = useAdmin()
    const navigate = useNavigate()
    const isAuthenticated = useSelector(state => state.adminState.isAuthenticated)
    const [formData, setFormData] = useState({
        userName: "",
        password: "",
        college: college,
        role: role
    })

    
    const token = localStorage.getItem("token")
    const localStorageCollege = localStorage.getItem("college")
    const localStorageRole = localStorage.getItem("role")
    if(token !== null && localStorageCollege === college && localStorageRole === role){
        const decode = jwtDecode(token)
        const currentTime = Date.now() / 1000;
        if(currentTime < decode.exp){
            dispatch(adminActions.setIsAuthenticated(true))
        }
        else{
            localStorage.removeItem("token")
            localStorage.removeItem("college")
            localStorage.removeItem("role")
        }
    }


    if(isAuthenticated){
        if(role === "superAdmin"){
            navigate("/superAdmin/home")
        }
        else if(role === "transportAdmin"){
            navigate("/transportAdmin/home")
        }
        else if(role === "adminStaff"){
            navigate("/adminStaff/" + college + "/home")
        }
        else{
            navigate("/admin/" + college + "/home")
        }
    }
    const handleClick = (e) => {
        e.preventDefault()
        // console.log(formData)
        login(formData)
    }

    const handleChange = (e) => {
        const { name, value } = e.target; 
        setFormData(prevState => ({
            ...prevState,
            [name] : value
        }));
    };

  return (
    <div className='LoginPage'>
        <div className="Banner">
            <Images imageLink={collegeBanner} imageText={"College Banner"} styles={{ height : "auto", width : "100%", maxHeight: "200px"}}/>
        </div>
        <div className="Wrapper">
            <form onSubmit={handleClick} className='form'>
                <div className="innerWrapper">
                    <div className="container">
                        <h1>Log In</h1>
                    </div>
                    <div className="inputBox">
                    <div className='label'>Email or Username</div>
                        <input className='loginInput' type="text" placeholder='Enter userName' name="userName" id="userName" onChange={handleChange}/>
                    </div>
                    <div className="inputBox">
                    <div className='label'>Password</div>
                        <input className='loginInput' type="password" placeholder='Enter password' name="password" id="password" onChange={handleChange}/>
                    </div>
                    <div className='submit'>
                        <button type="submit">Login</button>
                </div>
                </div>
            </form>
        </div>
    </div>
  )
}

export default LoginPage
