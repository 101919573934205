import axios from "axios"
import { busActions } from "../store/store"
import { useDispatch, useSelector } from 'react-redux'
import { failNotify, SuccessNotify } from "../components/notify"
import Url from "../Data/data"

function useBus() {
  // const url = "https://us-central1-cmrgi-transport.cloudfunctions.net/api/bus"
  const url = Url + "/bus"
  const dispatch = useDispatch()
  const buses = useSelector((state) => state.busState.buses)

  const fetchBuses = async (query) => {
    dispatch(busActions.setLoading(true))
    try{
      const res = await axios.get(url + "/getBusDetails", {
        params: query
      })
      dispatch(busActions.setBuses(res.data.result))
    }catch(err){
      failNotify(err.response.data.message)
    }finally{
      dispatch(busActions.setLoading(false))
    }
  }

  const converIdToBus = (id) => {
    return buses.filter((bus) => bus._id === id)
  }

  const searchBuses = (startLetters) => {
    if(buses && startLetters){
      const regex = new RegExp(`^${startLetters}`, 'i');
      const newArr = buses.filter(stop => regex.test(stop.boardingPoint));
      dispatch(busActions.setTempBuses(newArr))
    }
    else if(!startLetters){
      dispatch(busActions.setTempBuses(buses))
    }
  }

  const addBus = async (busDetails) => {
    dispatch(busActions.setLoading(true))
    try {
      const res = await axios.post(url + "/addBus", busDetails);
      SuccessNotify(res.data.message)
    } catch (err) {
      failNotify(err.response.data.message)
    }finally{
      dispatch(busActions.setLoading(false))
      fetchBuses();
    }
  }

  // const addRoutes = async (busDetails) => {
  //   dispatch(busActions.setLoading(true))
  //   try{
  //     await axios.post(url + "/addRoutes", busDetails)
  //     fetchBuses();
  //   }catch(err){
  //     dispatch(busActions.setError(err))
  //   }finally{

  //   }
  // }

  const deleteBus = async (busDetails) => {
    dispatch(busActions.setLoading(true))
    try{
      const res = await axios.post(url + "/deleteBus", busDetails)
      SuccessNotify(res.data.message)
    }catch(err){
      failNotify(err.response.data.message)
    }finally{
      dispatch(busActions.setLoading(false))
      fetchBuses();
    }
  }
  
  // const deleteRoutes = async (busDetails) => {
  //   try{
  //     await axios.post(url + "/deleteRoutes", busDetails)
  //     fetchBuses();
  //   }catch(err){
  //     setError(err)
  //   }
  // }

  return { fetchBuses, addBus, deleteBus, searchBuses, converIdToBus }

 }

export default useBus
