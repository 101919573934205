import { stopActions } from "../store/store"
import { useDispatch, useSelector } from 'react-redux'
import axios from "axios"
import { failNotify, SuccessNotify } from "../components/notify"
import Url from "../Data/data"

function useStops() {
  const dispatch = useDispatch()
  // const url = "https://us-central1-cmrgi-transport.cloudfunctions.net/api/stops"
  const url = Url + "/stops"
  const stops = useSelector((state) => state.stopState.stops)

  const fetchStops = async () => {
    dispatch(stopActions.setLoading(true))
    try{
        const res = await axios.get(url + "/getStopDetails");
        dispatch(stopActions.setStops(res.data.result))
        dispatch(stopActions.setMessage(res.data.message))
    }
    catch(err){
      failNotify(err.response.data.message)
    }
    finally{
      dispatch(stopActions.setLoading(false))
    }
  }

  const searchStops = (startLetters) => {
    if(stops && startLetters){
      const regex = new RegExp(`^${startLetters}`, 'i');
      const newArr = stops.filter(stop => regex.test(stop.boardingPoint));
      dispatch(stopActions.setTempStops(newArr))
    }
    else if(!startLetters){
      dispatch(stopActions.setTempStops(stops))
    }
  }

  const addStops = async (newStop) => {
    dispatch(stopActions.setLoading(true))
    try{
        const res = await axios.post(url + "/addStop", newStop)
        SuccessNotify(res.data.message)
      }
      catch(err){
        failNotify(err.response.data.message)
      }
      finally{
      fetchStops()
      dispatch(stopActions.setLoading(false))
    }
  }

  const deleteStop = async (stopDetails) => {
    dispatch(stopActions.setLoading(true))
    try{
      const res = await axios.post(url + "/deleteStop", stopDetails)
      SuccessNotify(res.data.message)
    }catch(err){
      failNotify(err.response.data.message)
    }
    finally{
      dispatch(stopActions.setLoading(false))
      fetchStops()
    }
  }

  const convertIdToStop = (id) => {
    return stops.filter((stop) => stop._id === id)
  }

  return { fetchStops, addStops, deleteStop, searchStops, convertIdToStop }
}

export default useStops
